import type { IntegratedDatabase } from '@/types/supabaseIntegrated'

export const useLoadIntegratedAlarmData = () => {
  const user = useSupabaseUser()

  const { schemaFetchOptionData } = useFetchComposable()

  const { integratedAlarmData } = storeToRefs(useNotificationsStore())

  const { refresh: refreshIntegratedAlarmData } = useAsyncData('integratedAlarm', async () => {
    if (!user.value?.id) {
      integratedAlarmData.value = []
      return []
    }

    const result = await schemaFetchOptionData('integrated', 'alarm', '*', 'owner_user_id', user.value?.id ?? '')
    integratedAlarmData.value = result as IntegratedDatabase['integrated']['Tables']['alarm']['Row'][]

    return result ?? []
  }, {
    immediate: true,
    server: false,
  })

  const executeIntegratedAlarm = async () => {
    await refreshIntegratedAlarmData()
  }

  return {
    executeIntegratedAlarm,
  }
}
